import React , { CSSProperties, useEffect } from 'react'

import { CreditCard } from '../../../components/payments/Creditcard'
import { Pix } from '../../../components/payments/Pix'

import useWindowSize from '../../../../API_CONFIGS/useWindowSize';

import { useAuth } from '../../../../AUTH_CONFIGS/Auth';

import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';


import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

import DragAndDropExample from '../DragAndDrop';
import { API_BASE, IMAGE_BASE, REACT_IMAGE_BASE, DEV_MODE, PROD_MODE } from '../../../../API_CONFIGS/constants';

import payButton from '../../../../media/icons/comprar-ps.png'
import paymentMethod from '../../../../media/icons/paymentMethod.png'
import safeBuy from '../../../../media/icons/safeBuy.jpg'
import number1 from '../../../../media/icons/number-1.svg'
import number2 from '../../../../media/icons/number-2.svg'
import number3 from '../../../../media/icons/number-3.svg'
import alert from '../../../../media/icons/idea-dark.svg'
import complete from '../../../../media/icons/completed.svg'
import celebration from '../../../../media/icons/celebration.svg'

import './step8.css'
import API from '../../../../API_CONFIGS/redux/API';

import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import axios, { AxiosRequestConfig } from 'axios';


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



interface Course {
  id:number;
  nome: string;
  valor: string;
  duracao: string;
  
}
interface Inscricao {
  id:number;
  status_id: number;
  payment_informed:boolean;
  payment_complete:boolean
  
}


interface Step8Props { 
  selectedCourse: Course;
  inscricao: Inscricao;
  setLoading: (loading: boolean) => void; // setLoading is a function that accepts a boolean parameter
}
   

const Step8Vestibular: React.FC<Step8Props> = ({ selectedCourse, inscricao , setLoading}) => {

  const {currentUser} = useAuth()


  const [expanded, setExpanded] = React.useState<string | false>('');
  const [active, setActive] = React.useState<boolean>(false);
  const [modalConfirmPayment, setModalConfirmPayment] = React.useState<boolean>(false);
  const [canSendFile, setCanSendFile] = React.useState<boolean>(true);
  const [checkoutData, setCheckoutData] = React.useState({ id: -1, nome: '', valor: 99, descricao: 'NOME DO CURSO | Descrição do curso', });
  const [paymentAmount, setPaymentAmount] = React.useState<number>(Math.round(checkoutData?.valor * 100) / 100|| 0);
  const [canPay, setCanPay] = React.useState<boolean>(false);
  // const [emailToSendPayment, setEmailToSendPayment] = React.useState<string>("suporte@lojamodelo.com.br");
  const [emailToSendPayment, setEmailToSendPayment] = React.useState<string>("fabioinfogenius@gmail.com");


  const { width } = useWindowSize()
  const isMobile = width < 800


  useEffect(()=>{
    console.log(selectedCourse?.valor);
    
    const this_valor = parseFloat(selectedCourse?.valor)  || 0
    
    console.log(this_valor);


    setCheckoutData({
      id:selectedCourse?.id || -1,
      nome: selectedCourse?.nome || '',
      valor: this_valor,
      descricao:  `${selectedCourse?.nome} | ${selectedCourse?.duracao}` || 'NOME DO CURSO | Descrição do curso',
    })
    setPaymentAmount(Math.round(this_valor * 100) / 100|| 0.00);
  },[selectedCourse])

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };



    const [buttonCompletedStyle, setButtonCompletedStyle] = React.useState<CSSProperties>({
      display:'flex',
      justifyContent:'center',
      alignItems:'center'  ,
      width:'250px',
      fontWeight:'bold',
      color:'#fff',
      fontSize:'12pt',
      margin:'0px auto 100px auto',
      height:'40px',
      border:'none',
      borderRadius:'6px',
      backgroundColor:'rgba(50, 216, 200, 1)',
      transition: 'transform 1s',
    });
    const handleHoverCompleted = () => {
      setButtonCompletedStyle((prevStyle) => ({
        ...prevStyle,
        transform: 'scale(1.2)',
      }));
    };
  
    const handleLeaveCompleted = () => {
      setButtonCompletedStyle((prevStyle) => ({
        ...prevStyle,
        transform: 'scale(1)',
      }));
    };

    const [buttonStyle, setButtonStyle] = React.useState<CSSProperties>({
      width:'300px', 
      display:'flex', 
      margin:'100px auto 20px auto',
      transition: 'transform 1s',
      animation: 'shake 1s infinite',
    });
  
    const handleHover = () => {
      setButtonStyle((prevStyle) => ({
        ...prevStyle,
        transform: 'scale(1.2)',
      }));
    };
  
    const handleLeave = () => {
      setButtonStyle((prevStyle) => ({
        ...prevStyle,
        transform: 'scale(1)',
      }));
    };
  
    const handleConfirmPayment = ()=>{
      setLoading(true)
      API.post(`aluno/sendPaymentReceipt/${inscricao?.id}`)
      .then((res)=>{
        window.location.reload()

      })
      .catch((error)=>{
        window.location.reload()
        console.error(JSON.stringify(error.response?.data?.error || 'Error occurred'));
      })
    }

  


    const handleAfterSend = ()=>{
      setCanSendFile(false)
      handleConfirmPayment()
    }





    const handlePaymentAmount = (e: React.ChangeEvent<HTMLInputElement>)=>{

      

      if (!e?.target?.value) {
        return setPaymentAmount(Math.round(checkoutData?.valor * 100) / 100|| 0.00);

      }
   
      const inputValue = e.target.value;

      const numericValue = parseFloat(inputValue);


      if (isNaN(numericValue)) {
        // Handle cases where the input is not a valid number
        return setPaymentAmount(Math.round(checkoutData?.valor * 100) / 100|| 0.00);
      }

      
    
      if (numericValue < checkoutData?.valor) {
        setPaymentAmount(Math.round(numericValue * 100) / 100);

        return setCanPay(false)
        
      }
      
      
      setPaymentAmount(Math.round(numericValue * 100) / 100);
      
      return setCanPay(true)
        

    }


    useEffect(()=>{
      if(paymentAmount >= checkoutData?.valor ){
        setCanPay(true)
      }
      else{
        setCanPay(false)
      }
    },[paymentAmount , checkoutData , ])






    const createCheckout = ()=>{

      setLoading(true)


      API.post('payments/createCheckout', {
        username: currentUser?.nome || '',
        email: currentUser?.email || '',
        tax_id: currentUser?.cpf || '',
        phone: currentUser?.numero || '',
        reference_id: checkoutData.id || `${Math.floor(Math.random() * 999999)}`,
        unit_amount: paymentAmount * 100 || 0,
        soft_descriptor: 'Curso',
        productname: checkoutData?.descricao || 'Curso',
        redirect_url: 'https://vestibular.faculdadereboucas.com.br/vestibular',
        return_url: 'https://vestibular.faculdadereboucas.com.br/vestibular',
        notification_urls: 'https://vestibular.faculdadereboucas.com.br/vestibular',
 
      }) 
      .then((res) => {
        console.log(res.data);
    
        interface Link {
          rel: string;
          href: string;
        }
    
        // Assuming res?.data?.links is an array of Link objects
        const linkToRedirect = (res?.data?.links as Link[])?.find(single => single?.rel === 'PAY') || { href: '' };
    
        // Check if linkToRedirect has href property
        if (linkToRedirect.href) {
          window.open(linkToRedirect.href);
        } else {
          console.error('No redirect link found.');
          // Handle the case when there is no redirect link available
        }

        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        setLoading(false)
      });

}







return (
    <div className='' style={{marginBottom:'20px'}}>



{modalConfirmPayment &&



  <div style={{display:'flex', width:'100vw', position:'fixed', height:'100vh', top:'0', left:'0', zIndex:'9999'}}>



    <div style={{width:'100vw', height:'100vh', position:'fixed', backgroundColor:'rgba(0,0,0,0.4)', top:'0', left:'0', zIndex:'0'}} onClick={()=>{setModalConfirmPayment(!modalConfirmPayment)}}/>
    

    <div style={{backgroundColor:'#fff', borderRadius:'10px', zIndex:'999', padding:'50px', margin:'auto', gap:'10px', display:'flex', flexDirection:'column', }}>
      <h1 style={{color:'rgba(0, 170, 0, 1)'}}>Confirmo que já efetuei o pagamento da taxa de matrícula</h1>
      <h6>Faça o upload do comprovante de pagamento. Assim a próxima etapa sserá liberada mais rápidamente.</h6>
      <h6>OBS.: Após a confirmação, a FRCG irá liberar a documentação para assinatura, em até 02 dias úteis.</h6>


      {/* <div style={{display:'flex', width:'100%', gap:'20px', justifyContent:'space-around', margin:'30px auto 30px auto'}}> */}

      {/* <button                                         style={{...buttonCompletedStyle, margin:'0px', backgroundColor:'#ccc'}} onClick={()=>{setModalConfirmPayment(false)}} >Cancelar</button> */}
      {/* <button onClick={()=>{handleConfirmPayment()}}  style={{...buttonCompletedStyle, margin:'0px', backgroundColor:'rgba(0, 152, 0, 0.7)'}}><img src={complete} style={{width:'30px', margin:'0px 10px 0px 0px'}} />Confirmar</button> */}




        <div style={{width:'100%', padding:'0px'}}>
          <DragAndDropExample       
              // setdocSelectedId={setdocSelectedId}           
              messageToShow={'Clique para fazer Upload do arquivo'}
              supportedFiles={"application/pdf, image/*"}
              // creating different routes for personal data on backend
              routeToSend={
                API_BASE+'aluno/sendPaymentReceipt/' + inscricao?.id
              } 
              // disabled={docSelectedId!=='-1'?false:true}
              // disabled={true}
              allowSendFile={true}
              multiple={false}
              handleAfterSend={handleAfterSend}
            />


        </div>
      {/* </div> */}


    </div>


  </div>

}




      <h1 style={{margin:'20px', fontSize:'16pt', fontWeight:'normal'}}><img   src={number1}  width={'30px'} style={{margin:'0px 10px 0px 10px'}}/>A seguir você será redirecionado ao PagSeguro, onde irá escolher entre as opções e realizar o pagamento. </h1>
      <h1 style={{margin:'20px', fontSize:'16pt', fontWeight:'normal'}}><img   src={number2}  width={'30px'} style={{margin:'0px 10px 0px 10px'}}/>Após a confirmação do pagamento, você poderá assinar os documentos de matrícula. </h1>
      <h1 style={{margin:'20px', fontSize:'16pt', fontWeight:'normal'}}><img   src={number3}  width={'30px'} style={{margin:'0px 10px 0px 10px'}}/>Caso já tenha feito o pagamento, aguarde a liberação dos documentos. Ela poderá ocorrer pela FRCG em até 2 dias úteis. </h1>





  {/* <input type='number'  onChange={handlePaymentAmount} value={paymentAmount}     /> */}






{inscricao?.payment_informed && !inscricao?.payment_complete &&
    <div className="waiting-container">
      <h1>Seu pagamento está sendo processado. Você poderá assinar os documentos em até 48 horas.</h1>
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    </div>
}

{inscricao?.payment_complete &&
    <div style={{display:'flex', width:'80%', gap:'20px', margin:'50px auto 50px auto', justifyContent:'center', alignItems:'center', color:'green'}}>
      < img  src={celebration}  width={'100px'}/ >
      <h2 style={{color:'green'}}>Que ótima notícia!<br/>Seus documentos foram liberados!<br/>Você pode assinar agora mesmo.</h2>
    </div>
}

{!inscricao?.payment_informed && !inscricao?.payment_complete &&

<>

<h1 style={{margin:'100px 50px 50px 20px', fontSize:'16pt', fontWeight:'normal'}}><img   src={alert}  width={'40px'} style={{margin:'0px 10px 0px 10px'}}/>Novidade! Agora você pode também adiantar o pagamento de suas mensalidades. Basta definir o valor total que deseja adiantar, abaixo. </h1>
<p style={{width:'100%', margin:'20px auto 20px auto', display:'flex', textAlign:'center', justifyContent:'center', alignItems:'center',  }}>Defina o valor que deseja pagar abaixo:</p>
  <TextField
        label="Valor total (R$)"
        InputProps={
          {
            style:{width:'200px', display:'flex', margin:'auto', fontSize:'12pt', justifyContent:'center', alignItems:'center',  },
        
          startAdornment: (
            <InputAdornment position="start" style={{width:'30%', display:'flex', justifyContent:'center', alignItems:'center', fontSize:'12pt', fontWeight:'bold',  }}>
              <p style={{margin:'10px', fontWeight:'bold',  }}>R$</p>
            </InputAdornment>
          ),
        }}
      
        type='number'

        error={!canPay}

        style={{width:'200px', display:'flex', margin:'auto', fontSize:'12pt', justifyContent:'center', alignItems:'center',  }}


          onChange={handlePaymentAmount} 
          value={paymentAmount}


      />

      {!canPay &&
          <p style={{width:'100%', margin:'10px auto 20px auto', display:'flex', textAlign:'center', justifyContent:'center', alignItems:'center', color:'red', fontWeight:'normal',  }}>Defina um valor válido</p>
      }



<div style={{display:'flex', flexDirection:'column', width:'100%', }}>


{canPay && emailToSendPayment &&

// {/* <form 

// style={buttonStyle} target="pagseguro" method="post"  action="https://pagseguro.uol.com.br/checkout/checkout.jhtml" acceptCharset='ISO-8859-1' encType='ISO-8859-1'>

//       <input type="hidden" name="email_cobranca" value={emailToSendPayment} /> 
  
  
//   <input type="hidden" name="tipo" value="CP" />
//   <input type="hidden" name="moeda" value="BRL" />

//   <input type="hidden" name="item_id_1" value={checkoutData.id || `${Math.floor(Math.random() * 999999) + 1}`} />
//   <input type="hidden" name="item_descr_1" value={checkoutData?.descricao || 'Curso'} />
//   <input type="hidden" name="item_quant_1" value="1" />
//   <input type="hidden" name="item_valor_1" value={paymentAmount * 100 || 0} />

//         {currentUser?.nome && <input type="hidden" name="cliente_nome" value={currentUser?.nome || ''} /> }  
//         {currentUser?.cep && <input type="hidden" name="cliente_cep" value={currentUser?.cep || ''} /> } 
//         {currentUser?.endereco && <input type="hidden" name="cliente_end" value={currentUser?.endereco || ''} /> } 
//         {currentUser?.numero && <input type="hidden" name="cliente_num" value={currentUser?.numero || ''} /> } 
//   <input type="hidden" name="cliente_compl" value={currentUser?.complemento || ' - - - '} />
//         {currentUser?.bairro && <input type="hidden" name="cliente_bairro" value={currentUser?.bairro || ''} /> }  
//         {currentUser?.cidade && <input type="hidden" name="cliente_cidade" value={currentUser?.cidade || ''} /> }  
//         {currentUser?.estado && <input type="hidden" name="cliente_uf" value={currentUser?.estado || ''} /> }  
//   <input type="hidden" name="cliente_pais" value="BRA" />
//         {currentUser?.email && <input type="hidden" name="cliente_email" value={currentUser?.email || ''} /> }



//   <input
//       style={{width:'300px', margin:'auto',}}
//       onMouseEnter={handleHover}
//       onMouseLeave={handleLeave}

//       type="image"
//       src={payButton}
//       name="submit"
//       alt="Pague com PagBank - é rápido, grátis e seguro!"
//     />
    
    
//   </form> */}
  <input
      style={buttonStyle}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}

      onClick={()=>{createCheckout()}}

      type="image"
      src={payButton}
      name="submit"
      alt="Pague com PagBank - é rápido, grátis e seguro!"
    />

}


<button onClick={()=>{setModalConfirmPayment(true)}} onMouseEnter={handleHoverCompleted} onMouseLeave={handleLeaveCompleted}  style={buttonCompletedStyle}><img src={complete} style={{width:'30px', margin:'0px 10px 0px 0px'}}/>Já fiz o pagamento</button>


</div>



<div style={{width:'100%', display:'flex', flexDirection:isMobile?'column':'row', alignItems:isMobile?'center':'', gap:'20px', justifyContent:'center'}}>
  <img  src={paymentMethod} width={'250px'} height={'100px'}/>
  <img  src={safeBuy} width={'250px'} height={'100px'}/>
</div>

</>

}


   </div>
  )
}

export {Step8Vestibular}
 